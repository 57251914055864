import React, { useState } from 'react';
import PartyDetails from '../../components/Party/MyPartyPortal/PartyDetails';
import PartyPortal from '../../components/Party/MyPartyPortal/PartyPortal';
import CloseParty from '../../components/Party/MyPartyPortal/CloseParty';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const MyPartyPortal = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [selectedParty, setSelectedParty] = useState(location.state?.selectedParty ?? null);
    const [activeStep, setActiveStep] = useState(location.state?.activeStep ?? 1);
    
    return (

        <>
            <div className="page_head_top">{t('myPartyPortal')}</div>
            <div className="ewallet_table_section">
                <div className="ewallet_table_section_cnt">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <PartyDetails />
                            <PartyPortal selectedParty={selectedParty} setSelectedParty={setSelectedParty} activeStep={activeStep}/>
                            <CloseParty selectedParty={selectedParty}/>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MyPartyPortal