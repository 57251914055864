import React from 'react';
import { ApiHook } from '../../../hooks/apiHook';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

const PartyProducts = (props) => {
  const { t } = useTranslation();

  return (

    <div className="table-responsive min-hieght-table-2 mb-4">
      <div className="p-3">
        <div className="py-0">
          <h5 className="m-0">{t('addToCart')}</h5>
        </div>

        <table className="table table-manage-invite-party-portal mb-0 border-top1">
          <thead>
            <tr>
              <th>{t('#')}</th>
              <th width="40%">{t('product_name')}</th>
              <th>{t('price')}</th>
              <th>{t('stock')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(props?.products) ? (
              props?.products?.map((product, index) => (
                <ProductRow
                  key={`${product.id}-${index}`}
                  product={product}
                  props={props}
                  index={index}
                  t={t}
                />
              ))
            ) : (
              <tr>
                <td colSpan="6">{t('noDataFound')}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

  );
};

const ProductRow = ({ product, index, props, t }) => {
  const queryClient = useQueryClient();
  const location = useLocation()
  const partyId = location.state?.partyId ?? JSON.parse(localStorage.getItem('partyId'));
  const guestId = location.state?.guestId ?? JSON.parse(localStorage.getItem('guestId'));

  //------------------------------- API ------------------------------
  const addToCartMutation = ApiHook.CallAddToPartyCart()

  const addToCart = (e, productId) => {
    e.preventDefault();
    const body = {
      partyId: partyId,
      guestId: guestId,
      productId: productId,
      quantity: 1
    }
    addToCartMutation.mutate(body, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["party-products"] });
        } else {
          toast.error(t(res.description));
        }
      }
    })
  }

  return (
    <tr key={product.id}>
      <td>{index + 1}</td>
      <td>{product.name}</td>
      <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(product?.price, props.conversionFactor)}</td>
      <td>{product.stock}</td>
      <td>
        <form onSubmit={(e) => addToCart(e, product.id)}>
          <div className="d-flex">
            <button type="submit" className="btn btn-primary ms-2">{t('addToCart')}</button>
          </div>
        </form>
      </td>
    </tr>
  );
};

export default PartyProducts;
