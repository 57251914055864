import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useTranslation } from 'react-i18next';

const ViewOrdersModal = ({ show, handleClose, details }) => {
  const { t } = useTranslation();

  const currency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  const total = (details?.quantity * details?.totalAmount)
  return (
    <Modal show={show} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t('viewOrders')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered className="table-manage-invite-party-portal">
          <thead>
            <tr>
              <th>{t('#')}</th>
              <th>{t('guestName')}</th>
              <th>{t('product_name')}</th>
              <th>{t('quantity')}</th>
              <th>{t('price')}</th>
              <th>{t('totalPrice')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{details?.id}</td>
              <td>{details?.guest}</td>
              <td>{details?.productName}</td>
              <td>{details?.quantity}</td>
              <td>{currency?.symbolLeft}{" "}{CurrencyConverter(details?.totalAmount, conversionFactor)}</td>
              <td>{currency?.symbolLeft}{" "}{CurrencyConverter(total, conversionFactor)}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewOrdersModal;
