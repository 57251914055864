import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const EwalletService = {
    callTiles: async (type) => {
        return callApi(`ewallet-tiles?type=${type}`);
    },
    callStatement: async (page, itemsPerPage, sortBy, direction, type) => {
        return callApi(
            `ewallet-statement?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}&type=${type}`
        );
    },
    callRebirth: async (page, itemsPerPage, sortBy, direction, type) => {
        return callApi(
            `rebirth-accounts?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}&type=${type}`
        );
    },
    callMonoline: async (page, itemsPerPage, sortBy, direction, type) => {
        return callApi(
            `monoline-entry?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}&type=${type}`
        );
    },
    callTransferHistory: async (
        page,
        itemsPerPage,
        selectedCategory,
        startDate,
        endDate,
        sortBy,
        direction,
        type
    ) => {
        return callApi(
            `/ewallet-transfer-history?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}&walletType=${type}`
        );
    },
    callPurchaseHistory: async (page, itemsPerPage, sortBy, direction) => {
        return callApi(
            `purchase-wallet?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
        );
    },
    callMyEarnings: async (
        page,
        itemsPerPage,
        selectedCategory,
        startDate,
        endDate,
        sortBy,
        direction
    ) => {
        return callApi(
            `my-earnings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`
        );
    },
    callEwalletBalance: async () => {
        return callApi("get-ewallet-balance");
    },
    callFundTransfer: async (data) => {
        return API.post("fund-transfer", JSON.stringify(data))
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
};
