import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useTranslation } from 'react-i18next';

const ProcessedOrders = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const totalQuantity = props?.orders?.reduce((sum, order) => sum + order.quantity, 0);
    const totalAmount = props?.orders?.reduce((sum, order) => sum + order.totalAmount, 0);

    return (
        <div className="table-responsive min-hieght-table-2 mb-4">
            <div className="p-3">
                <div className="py-0">
                    <h5 className="m-0">{t('processedOrders')}</h5>
                </div>

                <table className="table table-manage-invite-party-portal mb-4 border-top1">
                    <thead>
                        <tr className="th">
                            <th>{t('#')}</th>
                            <th>{t('product_name')}</th>
                            <th>{t('price')}</th>
                            <th>{t('quantity')}</th>
                            <th>{t('totalPrice')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.orders?.length > 0 ? (
                            props?.orders?.map((order, index) => (
                                <tr key={order.id}>
                                    <td>{index + 1}</td>
                                    <td>{order.productName}</td>
                                    <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(order?.price, props.conversionFactor)}</td>
                                    <td>{order.quantity}</td>
                                    <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(order?.totalAmount, props.conversionFactor)}</td>
                                </tr>
                            ))) : (<tr><td colSpan="5">{t('noDataFound')}</td></tr>)
                        }
                    </tbody>
                </table>
                {props?.orders?.length > 0 && (
                    <>
                        <table className="dashed_table_ttl_amnt mb-4">
                            <tbody>
                                <tr>
                                    <td>{t('quantity')} : </td>
                                    <td>{totalQuantity}</td>
                                </tr>
                                <tr>
                                    <td>{t('total')} :</td>
                                    <td><strong>{props.currency?.symbolLeft}{" "}{CurrencyConverter(totalAmount, props.conversionFactor)}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
                <Link
                    to="/order-party-products"
                    className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option"
                    style={{ marginRight: "7px" }}
                    state={{ 'selectedParty': location?.state.selectedParty }}
                >
                    {t('back')}
                </Link>
                <Link to='/my-party-portal' className="btn mt-1 py-2 rounded-1 setup-Party text-white btn-back-option" state={{ 'selectedParty': props?.selectedParty }}>{t('backToPartyPortal')}</Link>
            </div>
        </div>
    );
};

export default ProcessedOrders;
