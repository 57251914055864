import React from "react";
import DownlineMembersTable from "../../components/Tree/DownlineMembersTable";
import { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import PoolMembersTable from "../../components/Tree/PoolMembersTable";

const PoolMembers = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <TreeViewNavbar menu={"PoolMembers"} />
      <PoolMembersTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default PoolMembers;
