import React, { useState } from "react";
import { useSelector } from "react-redux";
import TreeViewNode from "../Tree/tree-view/TreeViewNode";
import { TreeViewSkeleton } from "../Tree/TreeViewSkeleton";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";


const TreeViewContentWeb = () => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState("");
  const treeListData = ApiHook.CallTreeViewList(selectedUserId);
  const TreeViewList = useSelector((state) => state.tree?.treeViewList);
  return (
    <div className="tree_web_view_content_section">
      <svg
        className="tree__background"
        style={{ height: "100%", width: "100%" }}
      >
        <pattern
          id="pattern-83098"
          x="6"
          y="0"
          width="10"
          height="10"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="0.4" cy="0.4" r="0.4" fill="#81818a"></circle>
        </pattern>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#pattern-83098)"
        ></rect>
      </svg>
      <div id="collapseDVR3" className="">
        {TreeViewList.data.length === 0 ? (
          <div className="text-center nodata-notify">
            <img className="nodata-image" src="/images/nodata.png" />
            <h5>{t('noData')}</h5>
          </div>
        ) : (
          <div className="tree">
            {treeListData.isLoading && selectedUserId === "" ? (
              <TreeViewSkeleton />
            ) : (
              <ul>
                {Array.isArray(TreeViewList?.data) &&
                  TreeViewList.data.map((item, index) => {
                    return (
                      <TreeViewNode
                        data={item}
                        key={index}
                        setSelectedUserId={setSelectedUserId}
                        selectedUserId={selectedUserId}
                        isLoading={treeListData.isLoading}
                      />
                    );
                  })}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TreeViewContentWeb;
