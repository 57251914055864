import React from "react";
import { formatDateWithoutTime } from "../../utils/formateDate";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";

const RebirthData = ({ members, count }) => {
  const { t } = useTranslation();
  const nav = useNavigate();


  const handleTileClick = () => {
    nav("/e-wallet/rebirth")
  }

  return (
    <div className="col-md-6">
      <div className="joinings_viewBox">
        {/* <div className="joinings_viewBox_head d-flex justify-content-between">
          <h5>Rebirth Accounts: ({count})</h5>
          <Link to={"/e-wallet/rebirth"} className="text-white">View All</Link>
        </div>
        <div className="teammbrs_cnt">
          {!members ? (
            <div className="teammbrs_cnt_row">
              <div className="teammbrs_cnt_img">
                <Skeleton
                  width="45px"
                  height="45px"
                  circle
                  containerClassName="avatar-skeleton"
                  count={3}
                />
              </div>
              <div className="teammbrs_cnt_name_dtl">
                <div className="teammbrs_cnt_name">
                  <Skeleton count={6} />
                </div>
              </div>
            </div>
          ) : members?.length === 0 ? (
            <div className="no-data-div">
              <div className="no-data-div-image">
                <img src="/images/nodata.png" alt="" />
              </div>
              <p className="text-white">{t('noDataFound')}</p>
            </div>
         ) : (
          members.map((member, index) => (
            <div className="teammbrs_cnt_row" key={index}>
              <div className="teammbrs_cnt_img">
                <img
                  src={
                    member?.image
                      ? member?.image
                      : member?.gender === "F"
                        ? "/images/team1.png"
                        : member?.gender === "M"
                          ? "/images/user-profile.png"
                          : "/images/team3.png"
                  }
                />
              </div>
              <div className="teammbrs_cnt_name_dtl">
                <div className="teammbrs_cnt_name">
                </div>
                <div className="teammbrs_cnt_date_id">
                <span>{member?.username}</span>
                  <span>{formatDateWithoutTime(member?.dateAdded)}</span>
                </div>
              </div>
            </div>
          ))
        )}
        </div>

*/}

        <div className="d-flex justify-content-between text-white" >
          <h5>Rebirth Accounts</h5>
          <Link to={"/e-wallet/rebirth"} className="text-white">View All</Link>
        </div>
        <div className="rebirth_dash_count">
          <div className="rebirth_dash_count_val" onClick={handleTileClick}>
            <span>
              {count}
            </span>
          </div>
        </div>


      </div>
    </div>
  );
};

export default RebirthData;
