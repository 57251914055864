import { React, useEffect, useRef, useState } from "react";
import FooterSection from "../components/Common/Footer";
import LeftSection from "../components/Common/LeftSection";
import HeaderSection from "../components/Common/HeaderSection";
import { Outlet, useLocation } from "react-router";
import RightContentSection from "../components/Dashboard/RightContent";
import layoutAnimation from "../utils/layoutAnimation";
import { Form, NavLink } from "react-router-dom";
import ShoppingCart from "../components/shopping/ShoppingCart";
import { ApiHook } from "../hooks/apiHook";
import VisitersForm from "../components/Common/modals/VisitersForm";
import '../custom.css'
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "../components/Common/MobileFooter";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { demoVisitorAdded } from "../store/reducers/userReducer";
import Cookies from "js-cookie";
import { use } from "i18next";
import { Modal } from "react-bootstrap";
import DashboardService from "../services/dashboard/Dashboard";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";


const MainLayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isLeftMenuVisible, setIsLeftMenuVisible] = useState(false);

  const dropdownRef = useRef(null);
  const toggleMenuRef = useRef(null);
  const toggleMobileRef = useRef(null);
  const containerRef = useRef(null);
  const [isQuickMenuOpen, setIsQuickMenuOpen] = useState(false);
  const [demoFormVisible, setDemoFormVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dashboardCheck, setDashboardCheck] = useState(false);

  const [show, setShow] = useState(false);
  const [isTemp, setIsTemp ] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const location = useLocation();

  const appLayout = ApiHook.CallAppLayout();
  const addName = DashboardService.CallAddName();
  const rightSection = ApiHook.CallDashboardRight(
    dashboardCheck,
    setDashboardCheck
  );
  // const checkDemoUser = ApiHook.CallCheckIsPresent();
  const userData = useSelector((state) => state.user?.loginResponse?.user)
  const userLang = useSelector((state) => state.user?.selectedLanguage)
  const user = JSON.parse(userData || "{}");



  // useEffect(() => {
  //   if (checkDemoUser?.data?.data?.isPreset === 1) {
  //     setCountries(checkDemoUser?.data?.data?.countries);
  //     const visitorId = Cookies.get("visitorID");
  //     if (visitorId) {
  //       setDemoFormVisible(false);
  //       dispatch(demoVisitorAdded(true));
  //     } else {
  //       setDemoFormVisible(true);
  //       dispatch(demoVisitorAdded(false));
  //     }
  //   }
  // }, [checkDemoUser]);

  useEffect(() => {
    // Update i18n language if savedLanguage exists
    if (userLang && userLang.code) {
      i18n.changeLanguage(userLang.code);
    }
  }, [userLang]);

  const handleLeftMenuToggle = () => {
    setIsLeftMenuVisible(!isLeftMenuVisible);
  };
  const handleQuickMenuToggle = () => {
    setIsQuickMenuOpen(!isQuickMenuOpen);
  };
  useEffect(() => {
    if (location.pathname) {
      setIsQuickMenuOpen(false);
      setIsLeftMenuVisible(false);
    }
    if (location.pathname === "/dashboard") {
      setDashboardCheck(true);
    }
    layoutAnimation(location, containerRef);
    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsQuickMenuOpen(false);
      }
      if (
        !(
          toggleMenuRef.current.contains(event.target) ||
          toggleMobileRef.current.contains(event.target)
        )
      ) {
        setIsLeftMenuVisible(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [location.pathname]);

  const handleClose = () => setShow(false);


  useEffect(() => {
    if (appLayout?.data?.isFullNameAdded === false && isTemp) {
      console.log('111111111111', appLayout?.data?.isFullNameAdded);
      setShow(true);
    }
  }, [appLayout]);


  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission, e.g., send data to the server
    console.log('First Name:', firstName);
    console.log('Last Name:', lastName);

    if (firstName.trim() === '') {
      toast.error("Please enter first name");
      return;
    }
    if (firstName.includes(' ') || lastName.includes(' ')) {
      toast.error("Cannot contain spaces");
      return;
    }

    addName.mutate({ name: firstName, secondName: lastName }, {
      onSuccess: (res) => {
        console.log("🚀 ~ handleSubmit ~ res:", res)
        if (res.data?.status) {
          handleClose()
          setIsTemp(false);
          toast.success(res?.data?.data);
          queryClient.invalidateQueries({ queryKey: ["app-layout"] });
        }
      },
    });
  };

 

  return (
    <>
      <div className={`${isLeftMenuVisible ? "left_menu_show" : ""}`}>
        <main
          className={
            location.pathname === "/dashboard" ? "dashboard_main_dv" : "main_dv"
          }
        >
          <section className="left_content_section">
            <HeaderSection
              count={appLayout?.data?.notificationCount}
              handleLeftMenuToggle={handleLeftMenuToggle}
              userName={user?.username}
              appLayout={appLayout?.data}
              toggleMobileRef={toggleMobileRef}
            />
            <LeftSection
              isLeftMenuVisible={isLeftMenuVisible}
              handleLeftMenuToggle={handleLeftMenuToggle}
              menus={appLayout?.data?.menu?.sideMenus}
              spclMenu={appLayout?.data?.menu?.spclMenu}
              toggleMenuRef={toggleMenuRef}
              logo={appLayout?.data?.companyProfile?.logo}
            />
            <div
              ref={containerRef}
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <div data-tut="main-layout" className="center_Content_section">
                <Outlet />
              </div>
            </div>
            {location.pathname === "/dashboard" && <FooterSection />}
          </section>
          {/* {location.pathname === "/dashboard" && (
            <section data-tut="rightSection" className="right_content_section">
              <RightContentSection props={rightSection?.data} />
            </section>
          )} */}
        </main>
        {location.pathname !== "/dashboard" && <FooterSection />}
        <div className="float_menu_btm" ref={dropdownRef}>
          <button
            className={`dropdown-toggle ${isQuickMenuOpen ? "show" : ""}`}
            onClick={handleQuickMenuToggle}
            aria-expanded={isQuickMenuOpen}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
          {isQuickMenuOpen && (
            <div
              className="dropdown-menu usr_prfl right-0 show"
              style={{
                position: "fixed",
                inset: "auto 0px 0px auto",
                margin: "0px",
                transform: "translate(-50px, -102px)",
              }}
              data-popper-placement="top-end"
            >
              <ul>
                {appLayout?.data?.menu?.quickMenus.map((menuItem, index) => (
                  <li key={index}>
                    <NavLink
                      to={`/${menuItem.slug}`}
                      className={`dropdown-item ${(isActive) => isActive && 'active'}`}
                    >
                      <i className={`${menuItem.quickIcon}`}></i>{" "}
                      {t(menuItem.slug)}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {(location.pathname === "/shopping" ||
          location.pathname === "/product-details") && <ShoppingCart isLeftMenuVisible={isLeftMenuVisible} />}
      </div>
      <MobileFooter
        menus={appLayout?.data?.menu?.sideMenus}
      />
      <VisitersForm
        isVisible={demoFormVisible}
        setIsVisible={setDemoFormVisible}
        countries={countries}
      />



      <Modal
        backdrop="static"
        show={show} onHide={handleClose}>
        <Modal.Header className="custom-modal-header">
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <form onSubmit={handleSubmit} class="container mt-2">
            <div class="mb-3">
              <label htmlFor="firstName" class="form-label">First Name:</label>
              <input
                autoFocus
                type="text"
                id="firstName"
                class="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
              />
            </div>

            <div class="mb-3">
              <label htmlFor="lastName" class="form-label">Last Name:</label>
              <input
                type="text"
                id="lastName"
                class="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
              />
            </div>

            <button type="submit" class="btn btn-primary w-100 p-2">Submit</button>
          </form>

        </Modal.Body>
      </Modal>



    </>
  );
};

export default MainLayout;
