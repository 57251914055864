import React, { useState } from "react";
import TableMain from "../Common/table/TableMain";
import PayoutTableFilter from "../Common/table/PayoutTableFilter";
import { getLastPage } from "../../utils/getLastPage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PayoutTable = (props) => {
  console.log("🚀 ~ PayoutTable ~ props:", props.spotData)
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("payout");
  const reportHeader = [t("date"), t("amount"), t("payout_method"), t("status")];
  const headers =
    activeTab !== "spot" ?
      ["date", "amount", "payout_method", "Type", "status"] :
      ["date", "amount", "Type"];
  const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);


  const handleTabChange = (tab) => {
    // setSortOptions({
    //   sortBy: "",
    //   direction: ""
    // });
    // if(tab === 'transfer_history' || tab === 'my_earnings'){
    //   setSelectedCategory("");
    //   setDateRange({startDate: "", endDate: ""});
    // }
    setActiveTab(tab);
    // props.setCurrentPage(1);
    // setApiTab(tab);
  };


  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="ewallet_table_section_cnt_tab_head">
          <Link
            className={`ewallet_tab_btn ${activeTab === "payout" ? "active" : ""
              }`}
            onClick={() => handleTabChange("payout")}
          >
            Payout History
          </Link>
          <Link
            className={`ewallet_tab_btn ${activeTab === "spot" ? "active" : ""
              }`}
            onClick={() => handleTabChange("spot")}
          >
            Rank Income Transfer history
          </Link>
        </div>

        {activeTab === "payout" && (<>
          <PayoutTableFilter
            setActiveTab={props.setActiveTab}
            activeTab={props.activeTab}
            headers={headers}
            reportHeader={reportHeader}
            data={props.data?.data}
            type={props.type}
            setCurrentPage={props.setCurrentPage}
          />
          <div className="table-responsive min-hieght-table">
            <TableMain
              headers={headers}
              data={props.data?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={lastPage}
              type={props.type}
              itemsPerPage={props.itemsPerPage}
              setItemsPerPage={props.setItemsPerPage}
              setCurrentPage={props.setCurrentPage}
              setEpinSortOptions={props.setSortOptions}
            />
          </div>
        </>)}
        {activeTab === "spot" && (<>
          {/* <PayoutTableFilter
            setActiveTab={props.setActiveTab}
            activeTab={props.activeTab}
            headers={headers}
            reportHeader={reportHeader}
            data={props.data?.data}
            type={props.type}
            setCurrentPage={props.setCurrentPage}
          /> */}
          <div className="table-responsive min-hieght-table">
            <TableMain
              headers={headers}
              data={props.spotData?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={lastPage}
              type={props.type}
              itemsPerPage={props.itemsPerPage}
              setItemsPerPage={props.setItemsPerPage}
              setCurrentPage={props.setCurrentPage}
              setEpinSortOptions={props.setSortOptions}
            />
          </div>
        </>)}
      </div>
    </div>
  );
};

export default PayoutTable;
