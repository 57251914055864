import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import TablePagination from "../../Common/table/TablePagination";

const TableViewAllParty = (props) => {
    const { t } = useTranslation();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const startPage = 1;
    const parties = ApiHook.CallAllParties(itemsPerPage, currentPage);

    const handleClosedPartyClick = () => {
        toast.error(t('partyIsClosed'));
    };
    const handleItemsPerPageChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1);
    }
    const toNextPage = () => {
        if (currentPage < parties?.data?.totalPages) {
            setCurrentPage(parties?.data?.currentPage + 1);
        }
    }
    const toLastPage = () => {
        setCurrentPage(parties?.data?.totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    return (
        <>
            <div className="filter_Section">
                <div className="row justify-content-end align-items-center">
                    <div className="col-md-4">
                        <div className="right_search_div d-flex gap-1 justify-content-end">
                            <Link to="/setup-party"><button className="btn btn-primary"><i className="fa-solid fa-user-plus"></i> {t('addParty')}</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive min-hieght-table">
                <table className="striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('party')}</th>
                            <th>{t('hostName')}</th>
                            <th>{t('mobile')}</th>
                            <th>{t('address')}</th>
                            <th>{t('action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parties?.data?.parties.map((row, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.partyName}</td>
                                <td>{row.hostName}</td>
                                <td>{row.phone}</td>
                                <td>{row.address}</td>
                                <td className="d-flex gap-2">
                                    {row.isClosed ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{ opacity: 0.6, cursor: 'not-allowed' }}
                                            title={t('partyIsClosed')}
                                            onClick={handleClosedPartyClick}
                                        >
                                            {t('edit')}
                                        </button>
                                    ) : (
                                        <NavLink to="/setup-party" state={{ partyId: row.value }}>
                                            <button type="button" className="btn btn-primary">{t('edit')}</button>
                                        </NavLink>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {parties?.data?.totalPages > 0 &&
                    <TablePagination
                        startPage={1}
                        currentPage={parties?.data?.currentPage}
                        totalPages={parties?.data?.totalPages}
                        itemsPerPage={parties?.data?.itemsPerPage}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        toNextPage={toNextPage}
                        toLastPage={toLastPage}
                        toPreviousPage={toPreviousPage}
                        toFirstPage={toFirstPage}
                    />
                }
            </div>
        </>
    )
};

export default TableViewAllParty;