import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ApiHook } from "../../../hooks/apiHook";
import { PhoneInput } from "react-international-phone";

const SelectHost = (props) => {
    const { t } = useTranslation();

    const [selectedHostType, setSelectedHostType] = useState(props.formValues?.hostType);
    const [selectedHost, setSelectedHost] = useState(props.formValues?.hostData);
    const [country, setSelectedCountry] = useState(props?.formValues?.hostData?.countryId ?? null);
    const [selectedState, setSelectedState] = useState(props?.formValues?.hostData?.stateId ?? null);
    const [phoneNumber, setPhoneNumber] = useState(props?.formValues?.hostData?.phone ?? "");

    const hostOptions = [
        { value: "existing", label: (t('chooseAnExistinghost')) },
        { value: "self", label: t('iamTheHost') },
        { value: "new", label: t('createNewHost') }
    ];

    // api
    const countries = ApiHook.CallGetCountries();
    const states = ApiHook.CallGetStates();
    const HostDetails = ApiHook.CallGetPartyHosts(0, 1);


    const existingHosts = () => {
        if (HostDetails && HostDetails.data && HostDetails.data.hosts) {
            return HostDetails.data.hosts.map((host) => ({
                value: host.id,
                label: `${host.name} ${host?.secondName || ''}`.trim()
            }));
        }
        return []; // Return an empty array if data is not available
    }
    const stateList = () => {
        if (!props?.formValues?.hostData?.countryId) {
            return []; // Return an empty array if there's no country specified.
        }

        const selectedCountry = props.formValues?.hostData?.countryId

        if (!selectedCountry) {
            return []; // Return an empty array if the selected country is not found.
        }

        return states?.data?.filter(state => state.countryId === selectedCountry.value)
    };
    // form handlers
    const handleSelectHostType = (selected) => {
        setSelectedHostType(selected);
        props.setValue("hostType", selected);
        props.setValue("hostData", {});
    };
    const handleSelectedHost = (selected) => {
        setSelectedHost(selected);
        props.setValue("hostData", { hostId: selected.value, value: selected.value, label: selected.label });
    }
    const handlePhoneNumber = (phone) => {
        setPhoneNumber(phone);
        props.setValue("hostData.phone", phone);
        props.clearErrors("hostData.phone");
    };
    const countryChange = (country) => {
        setSelectedCountry(country);
        props.setValue("hostData.countryId", country);
        props.clearErrors("hostData.countryId");
        props.setValue("hostData.stateId", null);
        setSelectedState(null);
    };
    const changeState = (state) => {
        setSelectedState(state?.value);
        props.setValue("hostData.stateId", state.value);
        props.clearErrors("hostData.stateId");
    };

    return (
        <>
            <div className="row row_top justify-content-center form-business">
                {props.partyId ? (
                    <div className="regsiter_step_1_view_left_sec_head">{t('hostName')}</div>
                ) : (
                    <div className="regsiter_step_1_view_left_sec_head">{t('selectTheHost')}</div>
                )
                }
                <div className="regsiter_second_step_bx">
                    {!props.partyId &&
                        <div className="host-select">
                            <Select
                                isSearchable={false}
                                options={hostOptions}
                                onChange={handleSelectHostType}
                                value={selectedHostType}
                                isDisabled={props.partyId}
                            />
                            <span className="error-message-validator">
                                {t(props.errors?.hostType?.message)}
                            </span>
                        </div>
                    }
                    {selectedHostType?.value === "existing" &&
                        <>
                            <br />
                            <div className="host-select">
                                <Select
                                    options={existingHosts()}
                                    onChange={handleSelectedHost}
                                    value={selectedHost}
                                    isDisabled={props.partyId}
                                />
                            </div>
                        </>
                    }
                    {selectedHostType?.value === "new" &&
                        <div className="row">
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('firstName')}<span>*</span></label>
                                    <input
                                        id="hostName"
                                        {...props.register("hostData.name", { required: "This field is required" })}
                                        type="text" className="form-control" placeholder={t("firstName")}
                                        onBlur={async () => await props.trigger("hostData.name")}

                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.hostData?.name?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('lastName')}<span>*</span></label>
                                    <input
                                        id="hostSecondName"
                                        {...props.register("hostData.secondName", { required: "this_field_is_required" })}
                                        onBlur={async () => await props.trigger("hostData.secondName")}
                                        type="text" className="form-control" placeholder={t("lastName")}
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.hostData?.secondName?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="regsiter_form_box">
                                    <label>{t('address')}<span>*</span></label>
                                    <textarea
                                        {...props.register("hostData.address", { required: "this_field_is_required" })}
                                        onBlur={async () => await props.trigger("hostData.address")}
                                        type="text" className="form-control" placeholder={t("address")}
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.hostData?.address?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('mobile')}<span>*</span></label>
                                    <PhoneInput
                                        className=""
                                        defaultCountry="us"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumber}
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.hostData?.phone?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('country')}</label>
                                    <Select
                                        id="countryId"
                                        {...props.register("hostData.countryId", {
                                            required: false
                                        })}
                                        onBlur={async () =>
                                            await props.trigger("hostData.countryId")
                                        }
                                        options={countries?.data}
                                        onChange={countryChange}
                                        defaultValue={props.formValues.hostData.countryId}
                                        value={country}
                                        isSearchable
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.hostData?.countryId?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('state')}</label>
                                    <Select
                                        isSearchable
                                        id="stateId"
                                        {...props.register("hostData.stateId", {
                                            required: false
                                        })}
                                        options={country ? stateList(states?.data) : []}
                                        value={selectedState ? stateList(countries)?.find(
                                            (state) => state?.value === (selectedState.value ?? selectedState)
                                        ) : ""}
                                        onChange={changeState}
                                        onBlur={async () => await props.trigger("hostData.stateId")}
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.hostData?.stateId?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('city')}</label>
                                    <input
                                        {...props.register("hostData.city", { required: false })}
                                        onBlur={async () => await props.trigger("hostData.city")}
                                        type="text" className="form-control" placeholder="City"
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.hostData?.city?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('email')}<span>*</span></label>
                                    <input
                                        id="email"
                                        {...props?.register("hostData.email", {
                                            required: t("this_field_is_required"),
                                            pattern: {
                                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                message: t(
                                                    "invalid_email_format"
                                                ),
                                            },
                                        })}
                                        onBlur={async () => await props.trigger("hostData.email")}
                                        type="text" className="form-control" placeholder="Email"
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.hostData?.email?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('zipCode')}<span>*</span></label>
                                    <input
                                        id="zip"
                                        {...props.register("hostData.zip", { required: t("this_field_is_required") })}
                                        type="text" className="form-control" placeholder="Zip Code"
                                        onBlur={async () => await props.trigger("hostData.zip")}
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.hostData?.zip?.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
};

export default SelectHost;