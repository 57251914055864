// const BASE_URL = "http://192.168.21.88:5008/node_api/";
// const BASE_URL = "http://162.19.146.134:1718/node_api/";
const BASE_URL = "https://admin.nebulx.io/node_api/";
// const BASE_URL = "https://user.sharath.iossmlm.com/node_api/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = '14959'
const HOSTING = 'LIVE'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID, HOSTING }
