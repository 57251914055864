import React from 'react';
import { useTranslation } from 'react-i18next';

const Wizard = ({ step }) => {
    const { t } = useTranslation();
    return (
        <div className="row justify-content-center pt-0 p-4" id="wizardRow">
            <div className="col-md-12 text-center">
                <h2 className="mb-45">{step === 1 ? t('Party Selection') : step === 2 ? t('Party Guest') : t('Party Orders')}</h2>
                <div className="wizard-form py-4 my-2 mt-3">
                    <ul id="progressBar" className="progressbar px-lg-5 px-0 mb-2 text-center">
                        <li className={`d-inline-block w-20 position-relative text-center progressbar-list ${step >= 1 ? 'active' : ''}`}>{t('step')}1</li>
                        <li className={`d-inline-block w-20 position-relative text-center progressbar-list ${step >= 2 ? 'active' : ''}`}>{t('step')}2</li>
                        <li className={`d-inline-block w-20 position-relative text-center progressbar-list ${step >= 3 ? 'active' : ''}`}>{t('step')}3</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Wizard;
