import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'

const PartySelection = (props) => {
    const { t } = useTranslation();

    return (
        <div className="row row_top justify-content-center text-center" id="cardSection">
            <h5>{t('SelectPartyToview')}</h5>
            <div className="regsiter_step_1_view_left_sec_head mb-0">{t('partyName')}</div>
            <div className="regsiter_second_step_bx">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="select-party-portal d-flex align-items-center gap-4">
                            <Select
                                options={props.parties}
                                onChange={props.handleSelectChange}
                                defaultValue={props.selectedParty}
                            />
                        </div>
                    </div>
                    {props.selectedParty &&
                        <div className="col-auto">
                            <div>
                                <img className="party_image" src={props.selectedParty.image} title='party' />
                            </div>
                        </div>
                    }
                    <div className="col-md-12 text-center">
                        <button type="button" className="btn text-white next mt-4 rounded-3 bg-color-info" onClick={props.onNext}>{t('next')}</button>
                    </div>
                    <div className="col-md-8">
                        <div className="d-flex justify-content-between">
                            {props.details && (
                                <>
                                    <span className="bg-light2">{`Start Date ${props.details?.startDate}`}</span>
                                    <span className="bg-light2">{`End Date ${props.details?.endDate}`}</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartySelection;
