import React from "react";
import { useTranslation } from "react-i18next";
import TableViewAllParty from "../../components/Party/MyPartyPortal/TableViewAllParty";

const ViewAllParty = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="page_head_top">{t('viewAllAddedParty')}</div>
            <TableViewAllParty />
        </>
    )
};

export default ViewAllParty;