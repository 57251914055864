import React from "react";
import { useTranslation } from "react-i18next";

const CreateParty = (props) => {
    const { t } = useTranslation();

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            props.setSelectedImage(imageUrl);
            props.setFile(file);
            props.setValue("file", file);
            props.setError("file", undefined);
        }
    };

    const handleRemoveImage = () => {
        props.setSelectedImage(null);
        props.setFile(undefined);
        props.setValue("file", null)
    };

    return (
        <>
            <div className="row row_top justify-content-center" id="cardSection">
                <div className="regsiter_step_1_view_left_sec_head">{t('partyNameAndImage')}</div>
                <div className="regsiter_second_step_bx">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="regsiter_form_box">
                                <label>{t('partyName')}</label>
                                <input
                                    {...props.register("partyName", { required: "this_field_is_required" })}
                                    onBlur={async () => await props.trigger("partyName")}
                                    type="text" className="form-control" placeholder="Party Name"
                                />
                                <span className="error-message-validator">
                                    {t(props.errors?.partyName?.message)}
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="party-image-upload-sec">
                                <label htmlFor="party-image-upload" className="party-file-upload">
                                    <i className="fa fa-upload" aria-hidden="true"></i> {t('UploadImage')}
                                </label>
                                <input id="party-image-upload" type="file" onChange={handleImageUpload} accept="image/*" />
                                {props.selectedImage && (
                                    <div className="right_product_box_image">
                                        <img src={props.selectedImage} alt="party" />
                                        <button onClick={handleRemoveImage} className="close_btn_image_party">
                                            <i className="fa-solid fa-close"></i>
                                        </button>
                                    </div>
                                )}
                            </div>
                            <span className="error-message-validator">
                                {t(props.errors?.file?.message)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CreateParty;