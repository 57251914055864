import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EditOrderModal from './EditOrderModal';
import ViewOrdersModal from './ViewOrderModal';
import CurrencyConverter from '../../../Currency/CurrencyConverter';
import { useQueryClient } from '@tanstack/react-query';
import { ApiHook } from '../../../hooks/apiHook';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const OrderManagement = (props) => {
  const { t } = useTranslation();
  const [editModalShow, setEditModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [details, setDetails] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);
  const [selectedGuestIds, setSelectedGuestIds] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const queryClient = useQueryClient();

  //------------------------------ API -------------------------------

  const processOrder = ApiHook.CallProcessOrder();

  const handleCheckboxChange = (orderId, guestId) => {
    setSelectedGuestIds((prevSelectedGuestIds) => {
      if (prevSelectedGuestIds.includes(guestId)) {
        return prevSelectedGuestIds
      } else {
        return [...prevSelectedGuestIds, guestId];
      }
    });
    setSelectedOrderIds((prevSelectedOrderIds) => {
      if (prevSelectedOrderIds.includes(orderId)) {
        return prevSelectedOrderIds.filter(id => id !== orderId);
      } else {
        return [...prevSelectedOrderIds, orderId];
      }
    })
  };

  const handleEditShow = (order) => {
    setEditModalShow(true);
    setDetails(order);
  }
  const handleEditClose = () => setEditModalShow(false);

  const handleViewShow = (order) => {
    setViewModalShow(true)
    setViewDetails(order)
  };
  const handleViewClose = () => setViewModalShow(false);

  const calculateTotals = (orders) => {
    return orders?.reduce(
      (totals, order) => {
        return {
          quantity: totals.quantity + order.quantity,
          price: totals.price + order.totalAmount
        };
      },
      { quantity: 0, price: 0 }
    );
  };

  const unprocessedTotals = calculateTotals(props.data?.unprocessedOrders);
  const processedTotals = calculateTotals(props.data?.processedOrders);

  const handleSubmit = () => {

    if (selectedOrderIds.length === 0) {
      return toast.error(t("selectOrdersToProcess"))
    }
    const data = {
      guestIdArr: selectedGuestIds,
      cartIdArr: selectedOrderIds
    }

    if (props.data?.unprocessedOrders?.length > 0) {
      processOrder.mutate(data, {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(t(res.data));
            queryClient.invalidateQueries({ queryKey: ['party-details'] })
          } else {
            toast.error(t(res.data.description));
          }
        }
      })
    }
  }
  return (
    <>
      <div className="row row_top justify-content-center form-business">
        <div className="row">
          <div className="col-md-9">
            <div className="regsiter_step_1_view_left_sec_head">{t('Enter the Party Orders into a Batch')}</div>
          </div>
          <div className="col-md-3 d-flex justify-content-end mt-sm-0 mt-2">
            {props.isClosed &&
              <Link className="btn mt-2 mb-3 rounded-2 setup-Party text-white" to="/order-party-products" state={{ 'selectedParty': props?.selectedParty }}>{t('enterOrderNow')}
              </Link>
            }
          </div>
        </div>

        {/* Unprocessed Orders Section */}
        <div className="col-md-12 mb-4">
          <div className="table_manage_paty_order_sec">
            <p>{t('unprocessedOrders')}</p>
            {props.data?.unprocessedOrders?.length > 0 ? (
              <>
                <table className="table table-bordered table-manage-invite-party-portal">
                  <thead>
                    <tr>
                      <th>{t('#')}</th>
                      <th>{t('select')}</th>
                      <th>{t('Guest Name')}</th>
                      <th>{t('count')}</th>
                      <th>{t('price')}</th>
                      {props.isClosed &&
                        <th>{t('Edit Order')}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {props.data?.unprocessedOrders?.map((order, index) => (
                      <tr key={order.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedGuestIds.includes(order.guestId) && selectedOrderIds.includes(order.id)}
                            onChange={() => handleCheckboxChange(order.id, order.guestId)}
                          />
                        </td>
                        <td>{order.guest}</td>
                        <td>{order.quantity}</td>
                        <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(order?.totalAmount, props.conversionFactor)}</td>
                        {props.isClosed &&
                          <td>
                            <button className="btn btn-border" onClick={() => handleEditShow(order)}>
                              <i className="fas fa-pen-to-square"></i>
                            </button>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="dashed_table_ttl_amnt">
                  <tbody>
                    <tr>
                      <td>{t('quantity')} :</td>
                      <td>{unprocessedTotals?.quantity}</td>
                    </tr>
                    <tr>
                      <td>{t('total')} :</td>
                      <td><strong>{props.currency?.symbolLeft}{" "}{CurrencyConverter(unprocessedTotals?.price, props.conversionFactor)}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <p>{t('noDataFound')}</p>
            )}
          </div>
        </div>
        {props.isClosed &&
          <div className="col-md-12 mb-4">
            <button onClick={handleSubmit} className="btn mt-2 mb-3 rounded-2 setup-Party text-white" href="#">{t('processSelectedOrdersNow')}</button>
          </div>
        }

        {/* Processed Orders Section */}
        <div className="col-md-12 mb-4">
          <div className="table_manage_paty_order_sec">
            <p>{t('processedOrders')}</p>
            {props.data?.processedOrders?.length > 0 ? (
              <>
                <table className="table table-bordered table-manage-invite-party-portal">
                  <thead>
                    <tr>
                      <th>{t('#')}</th>
                      <th>{t('guestName')}</th>
                      <th>{t('product_name')}</th>
                      <th>{t('count')}</th>
                      <th>{t('price')}</th>
                      <th>{t('viewOrder')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data?.processedOrders?.map((order, index) => (
                      <tr key={order.id}>
                        <td>{index + 1}</td>
                        <td>{order?.guest}</td>
                        <td>{order?.productName}</td>
                        <td>{order?.quantity}</td>
                        <td>{props.currency?.symbolLeft}{" "}{CurrencyConverter(order?.totalAmount, props.conversionFactor)}</td>
                        <td>
                          <button className="btn btn-border btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => handleViewShow(order)}>
                            <i className="fas fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="dashed_table_ttl_amnt">
                  <tbody>
                    <tr>
                      <td>{t('quantity')} :</td>
                      <td>{processedTotals?.quantity}</td>
                    </tr>
                    <tr>
                      <td>{t('total')} :</td>
                      <td><strong>{props.currency?.symbolLeft}{" "}{CurrencyConverter(processedTotals?.price, props.conversionFactor)}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <p>{t('noDataFound')}</p>
            )}
          </div>
        </div>

        <div className="col-md-12">
          <button type="button" className="btn btn-dark text-white float-start back mt-4 rounded-3" onClick={props.onBack}>Back</button>
        </div>
      </div>
      <EditOrderModal
        show={editModalShow}
        handleClose={handleEditClose}
        details={details}
        partyId={props.selectedParty} />
      <ViewOrdersModal
        show={viewModalShow}
        handleClose={handleViewClose}
        details={viewDetails} />
    </>
  );
};

export default OrderManagement;
