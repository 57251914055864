import React from 'react';
import PartyProducts from '../../components/Party/MyPartyPortal/PartyProducts';
import CartItems from '../../components/Party/MyPartyPortal/CartItems';
import ProcessedOrders from '../../components/Party/MyPartyPortal/ProcessedOrders';
import { useLocation } from 'react-router';
import { ApiHook } from '../../hooks/apiHook';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SelectPartyProducts = () => {
  const { t } = useTranslation();
  const location = useLocation()
  const partyId = location.state?.selectedParty?.partyId ?? JSON.parse(localStorage.getItem('partyId'));
  const selectedParty = location.state?.selectedParty;
  const guestId = location.state?.guestId ?? JSON.parse(localStorage.getItem('guestId'));
  if (guestId) {
    localStorage.setItem('guestId', guestId)
  }
  const currency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  // Call API for party products
  const partyProducts = ApiHook.CallPartyProducts(partyId, guestId);


  return (
    <>
      <div className="page_head_top">{t('selectAProduct')}</div>

      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          <PartyProducts
            partyId={partyId}
            guestId={guestId}
            products={partyProducts?.data?.products}
            currency={currency}
            conversionFactor={conversionFactor}
          />
          <CartItems
            partyId={partyId}
            guestId={guestId}
            orders={partyProducts?.data?.guestOrders?.unprocessedOrders}
            currency={currency}
            conversionFactor={conversionFactor}
          />
          <ProcessedOrders
            orders={partyProducts?.data?.guestOrders?.processedOrders}
            currency={currency}
            conversionFactor={conversionFactor}
            selectedParty={selectedParty}
          />
        </div>
      </div>

    </>
  );
};

export default SelectPartyProducts;
